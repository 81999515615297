<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Modifica rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="modificaRapportino()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    Cliente
                                </div>
                                <div class="value">
                                    {{ currentData.customers_full_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    Commessa
                                </div>
                                <div class="value">
                                    {{ currentData.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2024"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="editedRapportino.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="editedRapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="editedRapportino.ora_fine"
                                        placeholder="Seleziona ora di fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>


                        <!-- Note -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="editedRapportino.note"
                                        placeholder="Note lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button v-if="mostraFirmaCliente" type="button" class="btn_firma" :class="{ btn_signed: customerHasSigned }" @click="openSignature('cliente')">
                                Firma cliente
                            </button>
                            <button v-if="mostraFirmaTecnico" type="button" class="btn_firma" :class="{ btn_signed: technicianHasSigned }" @click="openSignature('tecnico')">
                                Firma tecnico
                            </button>
                        </div>

                        <div class="action">
                            <button type="submit" class="btn_crea_rapportino" :disabled="savingData">Salva</button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    // IonInput,
    // IonSelect,
    // IonSelectOption,
    IonIcon,
    //IonThumbnail
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";

import FirmaRapportino from "@/components/firme/FirmaRapportino";

export default defineComponent({
    name: "ModificaRapportino",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        // IonInput,
        // IonSelect,
        // IonSelectOption,
        IonIcon,
        //IonThumbnail
    },
    setup(props, context) {
        const currentData = ref({ ...props.data });
        
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const editedRapportino = reactive({
            cliente: currentData.value.rapportini_cliente,
            commessa: currentData.value.rapportini_commessa,
            data: currentData.value.rapportini_data,
            ora_inizio: createDateFromTimeString(currentData.value.rapportini_ora_inizio),
            ora_fine: createDateFromTimeString(currentData.value.rapportini_ora_fine),
            operatori: [userID],
            note: currentData.value.rapportini_note,
            firma_tecnico: null,
            firma_cliente: null,
            prodotti: [],
        });
        
        const successResponse = ref(false);

        const mostraFirmaCliente = ref(false);
        const mostraFirmaTecnico = ref(false);


        // Convert time field to ISO format
        function createDateFromTimeString(timeString) {
            const currentDate = moment();
            const dateString = currentDate.format('YYYY-MM-DD');
            const dateTimeString = `${dateString} ${timeString}`;

            return moment(dateTimeString, 'YYYY-MM-DD HH:mm').toISOString();
        }

        /**
         * 
         * ! Close modal after form data submit
         * 
         */
         async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * 
         * ! Handle close page
         * 
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * 
         * ! Mostro button per firmare in base alla firma/e mancante/i
         * 
         */
        function checkCommessaSettings() {
            if(
                currentData.value.projects_richiesta_firma_operatore && currentData.value.projects_richiesta_firma_operatore == '1'
                && (currentData.value.rapportini_firma_tecnico == null || currentData.value.rapportini_firma_tecnico == '')
            ) {
                mostraFirmaTecnico.value = true;
            }
            if(
                currentData.value.projects_richiesta_firma_cliente && currentData.value.projects_richiesta_firma_cliente == '1'
                && (currentData.value.rapportini_firma_cliente == null || currentData.value.rapportini_firma_cliente == '')
            ) {
                mostraFirmaCliente.value = true;
            }
        }
        checkCommessaSettings();

        /**
         * 
         * ! Open modal to sign for technician and customer
         * 
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: FirmaRapportino,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? editedRapportino.firma_tecnico : editedRapportino.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    // Signed, change button class
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            editedRapportino.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            editedRapportino.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    // Removed signature or closed modal, remove signature from rapportino
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            editedRapportino.firma_tecnico = null;
                            technicianHasSigned.value = false;
                        } else {
                            editedRapportino.firma_cliente = null;
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }



        /**
         * ! Create new rapportino
         */
        const savingData = ref(false);

        async function modificaRapportino() {
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(editedRapportino.ora_inizio);
            const ora_fine = new Date(editedRapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }
            //Controllo che ci sia almeno un tecnico selezioanto
            if (editedRapportino.operatori.length === 0) {
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra i operatori selezionati
            const isUSerSelected = editedRapportino.operatori.includes(userID);
            if (!isUSerSelected && editedRapportino.operatori.length != 0) {
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }

            savingData.value = true;

            const data_inizio_formatted = moment(editedRapportino.data).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(editedRapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(editedRapportino.ora_fine).format("HH:mm");

            const formData = new FormData();

            formData.append("rapportini_data", data_inizio_formatted);
            formData.append("rapportini_ora_inizio", ora_inizio_formatted);
            formData.append("rapportini_ora_fine", ora_fine_formatted);

            editedRapportino.operatori.forEach((operatore) => {
                formData.append("rapportini_operatori[]", operatore);
            });

            if(editedRapportino.note) {
                formData.append("rapportini_note", editedRapportino.note);
            }

            // In caso di firma presente, controllo che siano state inserite
            if(editedRapportino.firma_tecnico) {
                formData.append("rapportini_firma_tecnico", editedRapportino.firma_tecnico);
            }
            if(editedRapportino.firma_cliente) {
                formData.append("rapportini_firma_cliente", editedRapportino.firma_cliente);
            }

            try {
                const response = await apiRapportini.editRapportino(currentData.value.rapportini_id, formData);
                if (response.data.status === 8 || response.data.status === 5) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la creazione del rapportino", "toast_danger");
            } finally {
                savingData.value = false;
            }
        }


        return {
            // Icon
            closeModal,
            arrowBackOutline,
            close,
            modificaRapportino,
            // Data
            editedRapportino,
            // Cliente e progetto corrente
            currentData,
            // Signature
            mostraFirmaCliente,
            mostraFirmaTecnico,
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            savingData,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    /*border-radius: 4px;*/
    border-radius: 8px;
    /*padding: 10px;*/
    padding: 16px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px #00000040;
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000000;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: #00000080;
}
ion-select::part(icon) {
    opacity: 1;
    color: #086fa3;
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_rapportino,
.btn_carica_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_rapportino:disabled {
    touch-action: none;
    opacity: .55;
}
.btn_carica_foto {
    background-color: #94a3b8;
}

ion-button {
    --color: #ffffff;
}

.action {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    gap: 16px;
}
.btn_firma {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma:disabled {
    touch-action: none;
    opacity: .4;
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    /*margin-bottom: 16px;*/
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}
.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

/**
* Products selection
*/
.field_title.product_selection_trigger {
    display: flex;
    justify-content: space-between;
}

.field_title.product_selection_trigger .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_query_input {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_query_input::placeholder {
    color: #64748b;
}
.search_query_input:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.searched_result_list {
    overflow-y: scroll;
}
.searched_result_list.no_result {
    text-align: center;
    font-weight: 500;
    color: #64748b;

}
.searched_result_list .product,
.searched_result_list .commessa {
    padding: 4px 8px;
    margin-bottom: 8px;
}
.selected_product,
.selected_commessa {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.no_data {
    font-size: 14px;
}
</style>
