import axios from "axios";

export default {
    /**
     *
     * @param userID id of the logges user
     * @returns array of documenti_contabilita di tipo ordini interni (14)
     *
     * Get all documents for the logged user
     */
    async getDocumentiContabilita(userID: string, tipoDocumento: string) {
        const data = new FormData();
        data.append("limit", "100");
        data.append("where[documenti_contabilita_utente_id]", userID);
        if (tipoDocumento) {
            data.append("where[documenti_contabilita_tipo]", tipoDocumento);
        }
        data.append("orderby", "documenti_contabilita_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/documenti_contabilita`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getArticoliDocumento(documentoID: string) {
        const data = new FormData();
        data.append("where[documenti_contabilita_articoli_documento]", documentoID);
        console.log(`${process.env.VUE_APP_BASE_URL}`);

        const response = await axios.post(`rest/v1/search/documenti_contabilita_articoli`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async saveOrdineMateriale(data) {
        //const response = await axios.post(`http://192.168.11.140/firegui_crm_h2/projects/documenti/express_save`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}projects/documenti/express_save`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
