import axios from "axios";

export default {

  async getImpostazioni() {
    const data = new FormData();
    data.append("limit", "1");

    const response = await axios.post(`rest/v1/search/impostazioni_rapportini`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    
    return response;
  }

};
