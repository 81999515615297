
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonSpinner,
    IonIcon,
    // IonSelect,
    // IonSelectOption,
    modalController,
    onIonViewWillLeave,
    alertController,
} from "@ionic/vue";
import { ref, toRefs, } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { refresh, arrowBackOutline } from "ionicons/icons";

//import { StreamBarcodeReader } from "vue-barcode-reader";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiMagazzino from "@/services/magazzino";
import apiProdotti from "@/services/prodotti";

import BarcodeCustom from "@/components/Barcode/Barcode.vue";
import SelectMagazzino from "@/components/magazzino/SelectMagazzino.vue";

export default {
    name: "MovimentaCommessa",
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
        // IonSelect,
        // IonSelectOption,
        //StreamBarcodeReader,
        BarcodeCustom,
        SelectMagazzino,
    },
    setup(props, _context) {
        console.log(props.data);
        
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const userId = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const loading = ref(false);

        const tipi_movimenti = [
            { id: "1", tipo: "Carico" },
            { id: "2", tipo: "Scarico" },
            // { id: "3", tipo: "Giro Magazzino" },
        ];

        const { data } = toRefs(props);
        const currentCommessa = data.value;

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
         const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Close modal after form data submit
         */
         async function closeModalOnSubmit(successResponse, res) {
            if (successResponse) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }


        const magazzini = ref([]);
        async function loadMagazzini() {
            loading.value = true;
            try {
                const res = await apiMagazzino.getMagazzini();
                if (res.status === 200 && res.data.status === 0) {
                    magazzini.value = res.data.data;
                    // Preseleziono con il magazzino di default
                    const defaultMagazzino = magazzini.value.find((magazzino) => magazzino.magazzini_default == "1");
                    if (defaultMagazzino) {
                        setSelectedMagazzino(defaultMagazzino.magazzini_id);
                        //setSelectedMagazzino(defaultMagazzino);
                    }
                }
            } catch (error) {
                console.log(error);
                openToast("si è verificato un errore durante la richiesta dei magazzini", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadMagazzini();

        /**
         * ! CAUSALI DOCUMENTO IN BASE AL TIPO SCELTO
         */
        const causali = ref([]);
        async function loadCausali(tipo_movimento: string) {
            try {
                const res = await apiMagazzino.getCausali(tipo_movimento);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    causali.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle causali", "toast_danger");
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la richiesta delle causali", "toast_danger");
            }
        }

        /**
         *
         * ! Selezioni magazzino, tipo movimento e causale legata al tipo movimento
         *
         */

        const selectedMagazzino = ref(null);
        function setSelectedMagazzino(magazzino) {
            selectedMagazzino.value = magazzino;
        }

         // Giro magazzino
        const activeGiroMagazzino = ref(false);
        const selectedMagazzinoRicevente = ref(null);
        function setSelectedMagazzinoRicevente(magazzino) {
            if(magazzino == selectedMagazzino.value) {
                openToast("Il magazzino ricevente deve essere diverso da quello di origine", "toast_danger");
                return;
            }
            selectedMagazzinoRicevente.value = magazzino;
        }

        const selectedCausale = ref(null);
        const showCausale = ref(true);
        function setSelectedCausale(causale) {
            selectedCausale.value = causale;
        }
        // Default Vendita Merci
        setSelectedCausale("18");

        // Selezione tipo movimento
        const selectedMovimento = ref(null);
        function setSelectedMovimento(movimento) {
            activeGiroMagazzino.value = false;
            selectedMagazzinoRicevente.value = null;

            showCausale.value = true;
            selectedMovimento.value = movimento;

            // Giro magazzino, abilito select ricevente e nascondo causale
            if(movimento == 3) {
                activeGiroMagazzino.value = true;
                // Riguarda lo scarico ma uso la causale di giro magazzino
                causali.value = [
                    {
                        movimenti_causali_id: "28",
                        movimenti_causali_nome: "Giro magazzino",
                        movimenti_causali_tipo_movimento_id: "2",
                        movimenti_causali_tipo_movimento_value: "Scarico",
                        movimenti_causali_tipologia_movimento: "2",
                    }
                ]
                // Seleziona causale con id 28 ovvero Giro magazzino (scarico)
                setSelectedCausale("28");
            } else if(movimento == 2) {
                loadCausali(selectedMovimento.value);
                // Movimento Scarico seleziono causale Acquisto merci
                setSelectedCausale("1")
            } else {
                loadCausali(selectedMovimento.value);
                setSelectedCausale("18"); // Vendita merci per Carico
            }
        }
        // Default Scarico
        setSelectedMovimento("2");

        /**
         *
         * ! SOUND GENERATION
         *
         */
        const playScanSound = () => {
            const audioContext = new (window.AudioContext || window.AudioContext)();
            const oscillator = audioContext.createOscillator();
            const gainNode = audioContext.createGain();

            oscillator.type = "square"; // Tipo di onda sonora
            oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // Frequenza del suono in Hz (440 Hz è la nota A)

            oscillator.connect(gainNode);
            gainNode.connect(audioContext.destination);

            oscillator.start();

            gainNode.gain.exponentialRampToValueAtTime(0.00001, audioContext.currentTime + 0.5); // Durata del suono (0.5 secondi)

            oscillator.stop(audioContext.currentTime + 0.5);
        };

        /**
         * ! Gestione lettore Barcode
         */
        const products = ref([]);
        const scannerRef = ref(null);
        const readingBarcode = ref(false);
        const decodedText = ref("");


        const onLoaded = () => {
            console.log("loaded");
        };

        const disableNewMovimento = ref(false);
        const timeout = null;

        const onDecode = async (text) => {
            decodedText.value = text;
            // Leggo barcode e faccio chiamata per trovare il prodotto, mostrare titolo ed aggungerlo
            // disabilito temporaneamente tutte le tracce, le posso riabilitare manualmente dopo la chiamata
            const scannedBarcode = text;
            disableNewMovimento.value = false;

            // Sound
            playScanSound();

            try {
                const res = await apiProdotti.searchProductByBarcode(scannedBarcode);

                if (res.status === 200 && res.data.status === 0) {
                    if (res.data.data.length === 0) {
                        openToast("Non è stato rilevato alcun prodotto con il barcode letto", "toast_danger");
                        return;
                    } else {
                        let barcode = null;

                        if (res.data.data[0].fw_products_barcode) {
                            const parse = JSON.parse(res.data.data[0].fw_products_barcode);
                            barcode = parse.join(", ");
                        }

                        // Controllo prodotto già aggiunto, incremento quantità
                        const existingProduct = products.value.find(p => p.barcode === barcode);
                        if (existingProduct) {
                            existingProduct.quantity += 1;
                        } else {
                            products.value = [
                                {
                                    name: res.data.data[0].fw_products_name,
                                    barcode: barcode,
                                    product: res.data.data[0],
                                    quantity: 1,
                                },
                                ...products.value,
                            ];
                        }
                    }
                }
                //console.log(products.value);
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la scansione tramite barcode", "toast_danger");
            } finally {
                disableNewMovimento.value = false;
            }
        };

        function addQuantity(index) {
            products.value[index].quantity += 1;
        }
        function decreaseQuantity(index) {
            if (products.value[index].quantity > 1) {
                products.value[index].quantity -= 1;
            }
        }
        function updateQuantity(index) {
            if (products.value[index].quantity < 1 || isNaN(products.value[index].quantity)) {
                products.value[index].quantity = 1;
            }
        }

        /**
         *
         * ! RIMOZIONE PRODOTTO
         *
         */
        function removeProduct(index) {
            if (products.value.length > 0) {
                products.value.splice(index, 1);
            }
        }


        /**
         *
         * ! CREAZIONE MOVIMENTO
         *
         */
        async function creaMovimento() {
            if (!selectedMagazzino.value) {
                openToast("Il magazzino in cui effettuare il movimento è obbligatorio", "toast_danger");
                return;
            }
            if (!selectedMovimento.value) {
                openToast("Il tipo di movimento da effettuare è obbligatorio", "toast_danger");
                return;
            }
            if (!selectedMagazzinoRicevente.value && activeGiroMagazzino.value) {
                openToast("Non puoi creare il movimento senza aver selezionato il magazzino ricevente", "toast_danger");
                return;
            }
            if(activeGiroMagazzino.value && selectedMagazzinoRicevente.value == selectedMagazzino.value) {
                openToast("Il magazzino ricevente deve essere diverso da quello di origine", "toast_danger");
                return;
            }
            if (products.value.length === 0) {
                openToast("Non puoi creare il movimento senza aver aggiunto almeno un prodotto", "toast_danger");
                return;
            }

            scannerRef.value.stopScanning();

            disableNewMovimento.value = true;
            //loadingCreaMovimento.value = true;

            // Imposto mittente nel caso di giro magazzino
            let mittente = null;
            if(activeGiroMagazzino.value) {
                mittente = 4; // 4 = Giro magazzino
            }

            try {
                const res = await apiMagazzino.saveMovimento(selectedMagazzino.value, selectedMovimento.value, selectedCausale.value, userId, products.value, currentCommessa.appuntamenti_impianto, mittente);

                if (res.data.status === 0) {
                    openToast(`${res.data.txt}`, "toast_danger");
                } else {
                    products.value = [];
                    openToast(`${res.data.txt}`, "toast_success");
                    closeModalOnSubmit(true, res.data);
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la creazione del movimento", "toast_danger");
            } finally {
                //loadingCreaMovimento.value = false;
                disableNewMovimento.value = false;
            }
        }

        const handleCloseModal = async () => {
            const alert = await alertController.create({
                header: "Attenzione",
                message: "Uscendo dalla pagina verranno rimmossi eventuali prodotti aggiunti. Sei sicuro di voler uscire?",
                backdropDismiss: false,
                buttons: [
                    {
                        text: "Annulla",
                        handler: () => {
                            // Rimango sulla pagina
                        },
                    },
                    {
                        text: "Esci",
                        handler: () => {
                            closeModal();
                        },
                    },
                ],
            });

            return alert.present();
        };

        function truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength - 3) + '...';
            }
            return text;
        }
        

        onIonViewWillLeave(() => {
            // Rimuovo barcode se cambio pagina
            if (scannerRef.value && scannerRef.value.codeReader) {
                scannerRef.value.stopScanning();
                // Delete added products
                products.value = [];
            }
        });

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            arrowBackOutline,
            handleCloseModal,
            //Magazzini
            magazzini,
            setSelectedMagazzino,
            selectedMagazzino,
            setSelectedMovimento,
            selectedMovimento,
            //QR & Barcode
            onLoaded,
            onDecode,
            decodedText,
            scannerRef,
            //reloadScanning,
            products,
            creaMovimento,
            causali,
            setSelectedCausale,
            selectedCausale,
            disableNewMovimento,
            loadMagazzini,
            tipi_movimenti,
            removeProduct,
            addQuantity,
            decreaseQuantity,
            truncateText,
            updateQuantity,
            // Giro magazzino
            activeGiroMagazzino,
            selectedMagazzinoRicevente,
            setSelectedMagazzinoRicevente,
            showCausale,
            // Selezione commessa
            currentCommessa,
        };
    },
};
