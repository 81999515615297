import axios from "axios";

export default {
    async getTimbrature(userID: string) {
        const data = new FormData();
        data.append("depth", "3");
        data.append("limit", "100");
        data.append("where[presenze_dipendente]", userID.toString());
        //data.append("where[]", `(presenze_richiesta IS NULL OR presenze_richiesta = '')`);
        //data.append("where[]", `(presenze_richiesta IS NOT NULL OR presenze_richiesta <> '')`);
        data.append("orderby", "presenze_data_inizio");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/presenze`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        localStorage.setItem("presenze", JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    async timbraEntrata(dipendenteId, entrata, reparto = null, latitude = null, longitude = null, scope) {
        const data = new FormData();
        data.append("dipendente_id", dipendenteId);
        data.append("ora_entrata", entrata);
        data.append("reparto_id", reparto);
        if (latitude) {
            data.append("latitude", latitude);
        }
        if (longitude) {
            data.append("longitude", longitude);
        }
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraEntrata`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraEntrata`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async chiudiPresenza(dipendenteId, uscita, scope) {
        const data = new FormData();

        data.append("dipendente_id", dipendenteId);
        data.append("ora_uscita", uscita);
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraUscita`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraUscita`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Save straordinario
     */
    async editPresenza(id, ore, note) {
        console.log(ore, note);
        const data = new FormData();
        data.append("presenze_straordinario", ore);
        if (note) {
            data.append("presenze_note", note);
        }

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/rest/v1/edit/presenze/${id}`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}rest/v1/edit/presenze/${id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Scan QR code
     */
    async scanQrCode(qrValue, dipendente_id) {
        const data = new FormData();
        data.append("dipendente_id", dipendente_id);
        data.append("value", qrValue);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/qrcode/inquadraQr`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/qrcode/inquadraQr`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Scan QR code in commessa appointment
     */
    async scanQrCodeCommessa(qrValue, dipendente_id, cliente_id = null, commessa_id) {
        const data = new FormData();
        data.append("dipendente_id", dipendente_id);
        data.append("value", qrValue);
        data.append("cliente", cliente_id);
        data.append("commessa", commessa_id);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/qrcode/inquadraQrCommessa`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/qrcode/inquadraQrCommessa`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Crea presenza completa
     */
    async creaPresenza(data: Record<string, any>) {
        const response = await axios.post(`rest/v1/create/presenze`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Cancella presenza
     */
    async deletePresenza(presenza_id: string, user_id: string) {
        const data = new FormData();
        data.append("cancellazione_da_app", "1");
        data.append("user_id", user_id);
        const response = await axios.post(`rest/v1/delete/presenze/${presenza_id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },

    async timbraNFC(dipendente, reparto) {
        const data = new FormData();

        data.append("dipendente_id", dipendente);
        data.append("reparto_id", reparto);

        //const response = await axios.post(`http://192.168.11.122/firegui_crm_h2/modulo-hr/app/timbraNfc`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraNfc`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async statoPresenza(dipendenteId: string, dataPresenza: string) {
        const data = new FormData();
        data.append(
            "where[]",
            `presenze_dipendente = '${dipendenteId}' AND presenze_data_inizio = '${dataPresenza}' AND presenze_data_fine IS NULL OR presenze_data_fine = ''`
        );
        data.append("orderby", "presenze_data_inizio,presenze_ora_inizio");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/presenze`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response;
    },

    /**
     *
     * ! FUNZIONI PER SIMULATORE PRESENZE
     * * Aggiunta data sia in entrata che in uscita
     */
    async timbraEntrataSimulator(dipendenteId, entrata, reparto = null, latitude = null, longitude = null, scope, data_entrata) {
        const data = new FormData();
        data.append("dipendente_id", dipendenteId);
        data.append("ora_entrata", entrata);
        data.append("reparto_id", reparto);
        if (latitude) {
            data.append("latitude", latitude);
        }
        if (longitude) {
            data.append("longitude", longitude);
        }
        if (data_entrata) {
            data.append("data_entrata", data_entrata);
        }
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraEntrata`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraEntrata`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async chiudiPresenzaSimulator(dipendenteId, uscita, scope, data_uscita) {
        const data = new FormData();

        data.append("dipendente_id", dipendenteId);
        data.append("ora_uscita", uscita);
        data.append("data_uscita", data_uscita);
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraUscita`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraUscita`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! IMPOSTA GIUSTIFICATIVO PRESENZA
     */
    async setGiustificativo(presenza_id, giustificativo) {
        const data = new FormData();

        data.append("presenza_id", presenza_id);
        data.append("giustificativo", giustificativo);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/impostaGiustificativo`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/impostaGiustificativo`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! TIMBRATURA TRAMITE GPS IN DETTAGLIO APPUNTAMENTO
     */
    async timbraCoordinate(dipendenteId, giornata, orario, reparto, cliente, commessa, latitude = null, longitude = null, tipologia, scope) {
        const data = new FormData();
        data.append("dipendente_id", dipendenteId);
        data.append("giornata", giornata);
        data.append("orario", orario);
        data.append("reparto_id", reparto);
        data.append("cliente", cliente);
        data.append("commessa", commessa);
        if (latitude) {
            data.append("latitude", latitude);
        }
        if (longitude) {
            data.append("longitude", longitude);
        }
        data.append("tipologia", tipologia);
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraGPS`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraGPS`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! TIMBRATURA TRAMITE GPS IN DETTAGLIO APPUNTAMENTO
     */
    async addRapportino(presenza_id, rapportino_id) {
        const data = new FormData();
        data.append("presenze_rapportino_id", rapportino_id);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/rest/v1/edit/presenze/${id}`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}rest/v1/edit/presenze/${presenza_id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },


    async timbraNFCCommessa(dipendente_id, nfc_value, cliente_id = null, commessa_id) {
        const data = new FormData();

        data.append("dipendente_id", dipendente_id);
        data.append("value", nfc_value);
        data.append("cliente", cliente_id);
        data.append("commessa", commessa_id);

        //const response = await axios.post(`http://192.168.11.159/firegui_crm_h2/modulo-hr/app/timbraNfcCommessa`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraNfcCommessa`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
